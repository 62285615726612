import {
  addExemption,
  addMemo,
  getAttendanceGroupFeeds,
  getAttendanceMemoConfig,
} from '@api/index';
import { AttendanceGroupFeedResponse } from '@api/type';
import UserCalendar from '@components/UserCalender';
import MemoModal from '@components/UserCalender/MemoModal';
import UserCalendarFilter from '@components/UserCalender/UserCalendarFilter';
import { CalendarRangeMap } from '@contants/index';
import { UserInfoState } from '@reducers/userInfo/type';
import { markBizEnd, markBizStart } from 'common/monitor-js-sdk';
import { bizMetricsMap } from 'common/monitor-js-sdk/biz-metrics';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Toast } from 'shopee-ui-react';
import styles from './index.module.scss';

export interface MyAttendanceProps {
  feedId?: number;
  defaultRange?: number;
  hasExportPermission?: boolean;
  hasFilterPermission?: boolean;
}

const MyAttendance = (props: MyAttendanceProps) => {
  const { feedId, defaultRange, showExport, showFilter, name } = useParams();

  const [data, setData] = useState<AttendanceGroupFeedResponse>({});
  const [memoList, setMemoList] = useState<string[]>([]);
  const [memo, setMemo] = useState({
    reason: '',
    remark: '',
  });
  const [memoModalVisible, setMemoModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any>({
    id: Number(feedId),
    from: 0,
    days: CalendarRangeMap[defaultRange || 0],
    start: undefined,
    end: undefined,
  });

  const storeRef = useRef({
    clickDay: {} as any,
  });

  const userInfo = useSelector(
    (store: { userInfo: UserInfoState }) => store.userInfo
  );

  const title = useMemo(() => name || 'My-Attendance', [name]);
  const isMyAttendancePage = useMemo(() => title === 'My-Attendance', [title]);

  const handleAddExemption = async (staff: any) => {
    let op = 1;
    if (staff.exempt) {
      op = 0;
    }
    const { ret, exemptBy, exemptDate } = await addExemption({
      id: staff.id,
      op,
    });
    if (ret === 0) {
      const info = data.attendance?.filter(item => item.id === staff.id)[0];
      if (info) {
        info.exempt = !staff.exempt;
        info.exemptBy = exemptBy;
        info.exemptDate = exemptDate;
      }
      setData({
        ...data,
      });
    }
  };

  const handleOpenAddMemo = (staffId: number, day: any) => {
    if (userInfo.pk === staffId) {
      if (memoList.includes(day.memo)) {
        setMemo({
          reason: day.memo,
          remark: '',
        });
      } else if (day.memo && !memoList.includes(day.memo)) {
        setMemo({
          reason: 'Others',
          remark: day.memo,
        });
      } else {
        setMemo({
          reason: '',
          remark: '',
        });
      }
      storeRef.current.clickDay = day;
      setMemoModalVisible(true);
    }
  };

  const handleMemoModalConfirm = async () => {
    let memoStr = memo.reason;
    if (memo.reason === 'Others') {
      memoStr = memo.remark;
    }
    storeRef.current.clickDay.memo = memoStr;
    await addMemo({
      id: storeRef.current.clickDay.id,
      memo: memoStr,
      day: storeRef.current.clickDay.day,
    });
    setData({
      ...data,
    });
    setMemoModalVisible(false);
  };

  const handleStartChange = (value?: Date) => {
    if (value) {
      setOptions({
        ...options,
        start: dayjs(value).format('DD/MM/YYYY'),
      });
    } else {
      setOptions({
        ...options,
        start: undefined,
      });
    }
  };

  const handleEndDateChange = (value?: Date) => {
    if (value) {
      setOptions({
        ...options,
        end: dayjs(value).format('DD/MM/YYYY'),
      });
    } else {
      setOptions({
        ...options,
        end: undefined,
      });
    }
  };

  const handleRangeChange = (range: string) => {
    const listOptions = {
      ...options,
      start: undefined,
      end: undefined,
      days: range,
    };
    getAttendanceList(listOptions);
    setOptions({
      ...options,
      days: range,
    });
  };

  const transformDateStr = (str: string) => {
    const splitDatas = str.split('/').reverse();
    return splitDatas.join('-');
  };

  const handleSubmit = () => {
    const listOptions = {
      ...options,
    };
    if (!listOptions.end && listOptions.start) {
      listOptions.end = dayjs().format('DD/MM/YYYY');
      listOptions.days = undefined;
      setOptions(listOptions);
    } else if (listOptions.end && !listOptions.start) {
      Toast.error('Please Select a Start Date');
      return;
    } else if (
      listOptions.end &&
      listOptions.start &&
      dayjs(transformDateStr(listOptions.end)).isBefore(
        dayjs(transformDateStr(listOptions.start))
      )
    ) {
      Toast.error('Start Date/Time should be before End Date/Time.');
      return;
    }
    getAttendanceList(listOptions);
  };

  const handleExport = () => {
    const modeMap: any = {
      'My-Attendance': 'my_attendance',
      'My-Team': 'my_team',
    };
    const mode = modeMap[title] || 'my_attendance';
    const listOptions = {
      ...options,
    };
    if (!listOptions.start) {
      Toast.error('Please Select a Start Date');
      return;
    } else if (!listOptions.end) {
      listOptions.end = dayjs().format('DD/MM/YYYY');
    } else if (
      listOptions.end &&
      listOptions.start &&
      dayjs(transformDateStr(listOptions.end)).isBefore(
        dayjs(transformDateStr(listOptions.start))
      )
    ) {
      Toast.error('Start Date/Time should be before End Date/Time.');
      return;
    }
    const downloadUrl = `/api/attendance/export/?mode=${mode}&from_date=${listOptions.start}&to_date=${listOptions.end}`;
    window.open(downloadUrl, '_blank');
  };

  const getAttendanceList = async (listOptions: any) => {
    try {
      setLoading(true);
      const res = await getAttendanceGroupFeeds(listOptions);
      setData(res);
      markBizEnd({
        targetType:
          bizMetricsMap[
            `${
              isMyAttendancePage
                ? 'view-user-attendance-list'
                : 'view-team-attendance-list'
            }`
          ],
        code: res.ret || 0,
      });
    } catch (error: any) {
      markBizEnd({
        targetType:
          bizMetricsMap[
            `${
              isMyAttendancePage
                ? 'view-user-attendance-list'
                : 'view-team-attendance-list'
            }`
          ],
        code: error?.code,
        status: error?.status,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    performance.mark('FCP');
    getAttendanceList({
      ...options,
      id: Number(feedId),
      days: CalendarRangeMap[defaultRange || 0],
    });
    setOptions({
      ...options,
      id: Number(feedId),
      days: CalendarRangeMap[defaultRange || 0],
    });
    getAttendanceMemoConfig().then(res => {
      setMemoList(res.memos);
    });
  }, [feedId]);

  useEffect(() => {
    markBizStart(
      bizMetricsMap[
        `${
          isMyAttendancePage
            ? 'view-user-attendance-list'
            : 'view-team-attendance-list'
        }`
      ]
    );
  }, [isMyAttendancePage]);

  return (
    <div className={styles.my_attendance}>
      {title ? (
        <div className={styles.title_wrapper}>{title.replaceAll('-', ' ')}</div>
      ) : null}
      <div className={styles.date_wrapper}>
        <UserCalendarFilter
          hasExportPermission={Boolean(showExport)}
          hasFilterPermission={Boolean(showFilter)}
          currentRange={options.days}
          startDate={options.start}
          endDate={options.end}
          startDateChange={handleStartChange}
          endDateChange={handleEndDateChange}
          rangeChange={handleRangeChange}
          onSubmit={handleSubmit}
          onExport={handleExport}
        />
      </div>
      <UserCalendar
        {...data}
        addExemption={handleAddExemption}
        openAddMemo={handleOpenAddMemo}
        loading={loading}></UserCalendar>
      <MemoModal
        visible={memoModalVisible}
        memoList={memoList}
        reason={memo.reason}
        remark={memo.remark}
        confirm={handleMemoModalConfirm}
        reasonChange={reason => setMemo({ ...memo, reason })}
        remarkChange={remark => setMemo({ ...memo, remark })}
        setVisible={setMemoModalVisible}
      />
    </div>
  );
};

export default MyAttendance;
